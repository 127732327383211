<template>
  <HorizontalItemsScroller
    v-if="blok.slider"
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    is-fullscreen
    class="size-full"
    :class="[...marginClasses]"
    :show-buttons="false"
    :has-gradient="false"
  >
    <ScrollerItem
      v-for="(entry, index) in blok.items"
      :key="entry._uid"
      class="aspect-[3/4] w-96 max-w-full lg:w-[calc(33%-9px)] 2xl:w-[453px]"
      :class="{
        'mr-5 ': blok.items && index < blok.items.length - 1,
      }"
    >
      <StoryblokComponent
        :image-sizes="sizes[index]"
        class="flex"
        :ratio-mobile="3 / 4"
        :ratio-desktop="3 / 4"
        keep-headline
        :blok="{
          ...entry,
          variant: 'gradient',
          position: 'bottom-left',
          height: '100%',
          look: 'h1',
          object_fit: 'cover',
        }"
      />
    </ScrollerItem>
  </HorizontalItemsScroller>
  <div
    v-else
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="container grid max-w-screen-2xl grid-cols-2 gap-x-8 gap-y-4 md:grid-cols-6 xl:grid-cols-12"
    :class="[...marginClasses]"
  >
    <StoryblokComponent
      v-for="(entry, index) in blok.items"
      :key="entry._uid"
      :class="itemClasses[index]"
      :image-sizes="sizes[index]"
      :keep-headline="true"
      :blok="{
        ...entry,
        variant: 'gradient',
        position: 'bottom-left',
        height: 'lg',
        look: 'h1',
      }"
    />
  </div>
</template>

<script setup lang="ts">
import type { SbContentTriple } from '../types/storyblok'

const props = defineProps({
  fullWidth: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  blok: {
    type: Object as PropType<SbContentTriple>,
    default: () => {},
  },
})
const { marginClasses } = useStoryblokMargins(props.blok)

const sizes = computed(() => {
  const maxWidth = props.fullWidth ? '1400px' : '996px'

  const count = props.blok?.items?.length ?? 1
  if (count === 1) {
    return [
      `(max-width: ${maxWidth}) calc(100vw - 4rem), calc(${maxWidth} - 4rem)`,
    ]
  }

  if (count === 2) {
    return [
      `(max-width: 767px) calc(100vw - 4rem), (max-width: ${maxWidth}) calc(50vw - 3rem), calc(${maxWidth} / 2 - 3rem)`,
      `(max-width: 767px) calc(100vw - 4rem), (max-width: ${maxWidth}) calc(50vw - 3rem), calc(${maxWidth} / 2 - 3rem)`,
    ]
  }

  return [
    `(max-width: 767px) calc(100vw - 4rem), (max-width: 1279px) calc(50vw - 3rem), (max-width: ${maxWidth}) calc(100vw / 3 - 8rem / 3), calc(${maxWidth} / 3 - 8rem / 3)`,
    `(max-width: 767px) calc(100vw - 4rem), (max-width: 1279px) calc(50vw - 3rem), (max-width: ${maxWidth}) calc(100vw / 3 - 8rem / 3), calc(${maxWidth} / 3 - 8rem / 3)`,
    `(max-width: calc(${maxWidth} - 1px)) calc(100vw - 4rem), (max-width: ${maxWidth}) calc(100vw / 3 - 8rem / 3), calc(${maxWidth} / 3 - 8rem / 3)`,
  ]
})

const itemClasses = computed(() => {
  const count = props.blok?.items?.length ?? 1
  if (count === 1) {
    return ['col-span-2 md:col-span-6 xl:col-span-12']
  }

  if (count === 2) {
    return [
      'col-span-2 md:col-span-3 xl:col-span-6',
      'col-span-2 md:col-span-3 xl:col-span-6',
    ]
  }

  return [
    'col-span-2 md:col-span-3 xl:col-span-4',
    'col-span-2 md:col-span-3 xl:col-span-4',
    'col-span-2 md:col-span-6 xl:col-span-4',
  ]
})
</script>
